<template>
  <div class="home-monitor" style="margin: 0 24px;">
    <part-title title="蓝牙网关设备"></part-title>
    <div class="search">
      <template>
        <span class="el-span">设备序列号：</span>
        <el-input
          size="small"
          class="el-input"
          v-model="searchData.deviceSn"
          placeholder="请输入设备序列号">
        </el-input>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          class="search-btn">
          查询
        </el-button>
      </template>
    </div>
    <div class="add-new">
      <el-button size="small" type="primary" @click="addNew">添加设备</el-button>
    </div>
    <el-table
      :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="设备序列号">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceAddr"
        label="蓝牙mac地址">
      </el-table-column>
      <el-table-column
        align="center"
        prop="modelName"
        label="设备型号">
      </el-table-column>
      <el-table-column
        align="center"
        prop="creator"
        label="创建人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        :formatter="formatter"
        label="创建时间">
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>-->
<!--          <span v-else>-</span>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row, 'detail')" type="text">详情</el-button>
          <el-button @click="handleClick(scope.row, 'edit')" type="text" style="color: #e6a23c">编辑</el-button>
          <el-popover
            :ref="`popover-${scope.row.id}`"
            :content="`设备状态：${statusText}`"
            placement="top"
            trigger="click">
            <el-button @click="onClickGatewayStatus(scope.row.deviceSn)" slot="reference" type="text" style="color: #67c23a; margin-left: 10px">获取状态</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <Page
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="totalPage"
    ></Page>
    <detail-model :is-visible-detail-model="isVisibleDetailModel" @close="isVisibleDetailModel = false" :sn="currentSn"></detail-model>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { getGatewayList, getGatewayStatus } from '@/serve/module/HMManage'
import PartTitle from '@/components/Part'
import DetailModel from './detail'
import Page from '@/components/Page'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    DetailModel,
    Page
  },
  data () {
    return {
      moment: moment,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20, // 每页条数
        deviceSn: ''
      },
      totalPage: 1,
      tableData: [],
      isVisibleDetailModel: false,

      modelId: null,
      statusText: '-',
      currentSn: ''
    }
  },
  mounted () {
    this.getList()
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  methods: {
    addNew () {
      this.$router.push({
        path: '/deviceManage/homeMonitor/add',
        query: {
          type: 'add'
        }
      })
    },
    onSearch () {
      this.getList()
    },
    async getList () {
      try {
        const res = await getGatewayList(this.searchData)
        console.log('获取列表:', res)
        this.tableData = res.data.data || []
        this.totalPage = res.data.total
        this.pageNo = res.data.pageNo
        this.pageSize = res.data.pageSize
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    sizeChange (val) {
      this.searchData.pageSize = val
      this.getList()
    },
    pageChange (val) {
      this.searchData.pageNo = val
      this.getList()
    },
    handleClick (row, type) {
      if (type === 'edit') {
        this.$router.push({
          path: '/deviceManage/homeMonitor/add',
          query: {
            type: 'edit',
            sn: row.deviceSn
          }
        })
      }
      if (type === 'detail') {
        this.isVisibleDetailModel = true
        this.currentSn = row.deviceSn
      }
    },
    async onClickGatewayStatus (deviceSn) {
      this.statusText = ''
      try {
        const res = await getGatewayStatus({
          deviceSn
        })
        const { state } = res.data
        if (state === 1) this.statusText = '在线'
        else if (state === 2) this.statusText = '离线'
        else this.stateText = '-'
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    formatter (row, column) {
      return moment.unix(row.createAt / 1000).format('Y-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="scss">
.home-monitor {
  .search {
    margin-bottom: 12px;
    height: 32px;
    line-height: 32px;
    .el-input,.el-select {
      width: 150px;
      margin-right: 24px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
    }
    // .search-btn {
    //   font-size: 14px;
    // }
  }
  .add-new {
    margin-bottom: 12px;
  }
}
</style>
